import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Vant from 'vant';
import 'vant/lib/index.css';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import Chat from 'jwchat';//聊天界面UI
import TIM from 'tim-js-sdk';//腾讯IM
import TIMUploadPlugin from 'tim-upload-plugin';//腾讯IM上传模块
import TIMProfanityFilterPlugin from 'tim-profanity-filter-plugin';//聊天信息本地审核模块
import '@/version' // app版本控制

let options = {
  SDKAppID:1400808356
  // SDKAppID: 1400807678  // 接入时需要将0替换为您的即时通信 IM 应用的 SDKAppID
};
// 创建 SDK 实例，`TIM.create()`方法对于同一个 `SDKAppID` 只会返回同一份实例
export  const tim = TIM.create(options); // SDK 实例通常用 tim 表示


tim.setLogLevel(1); // 普通级别，日志量较多，接入时建议使用
// tim.setLogLevel(1); // release 级别，SDK 输出关键信息，生产环境时建议使用
//tim.setLogLevel(5) //上线级别


// 注册腾讯云即时通信 IM 上传插件
tim.registerPlugin({'tim-upload-plugin': TIMUploadPlugin});


// 注册腾讯云即时通信 IM 本地审核插件
tim.registerPlugin({'tim-profanity-filter-plugin': TIMProfanityFilterPlugin});



//打包时去除所有console.log
if (window) {
  window.console.log = function () {};
}











Vue.use(ElementUI);
Vue.use(Chat)
Vue.use(Vant);
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
