/* eslint-disable comma-dangle */
/* eslint-disable prefer-arrow-callback */
import axios from 'axios';
import { Toast } from 'vant';
import NProgress from 'nprogress';
import 'nprogress/nprogress.css';
import { serverUrl, isLogin } from './tools';

// 创建一个axios实例
const instance = axios.create({
  timeout: 5000, // 请求超时时间
  baseURL: serverUrl, // 网络请求的基础地址
});

// https://github.com/axios/axios
// 全局拦截器
// Add a request interceptor
//  全局请求拦截，发起网络请求之前执行
instance.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    NProgress.start(); // 启动进度条
    // eslint-disable-next-line no-param-reassign
    config.headers.token = isLogin();
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);


// 全局响应拦截，网络请求完成之后执行
instance.interceptors.response.use(
  function (response) {
    // token失效
    // if (response && response.data && response.data.code && response.data.code === 5001) {
    //   Toast.fail('请重新登录');
    //   setTimeout(() => {
    //     window.location.href = '/#/login';
    //     }, 3000); 
    // }
    NProgress.done(); // 关闭进度条
      return response.data //有返回值
    
    
  },
  function (error) {
    console.dir(error);
    // token失效
    // if (error.code === 5001) {
    //   Toast.fail('请重新登录');
    //   setTimeout(() => {
    //     window.location.href = '/#/login';
    //   }, 3000);
    // }
    NProgress.done(); // 关闭
    return Promise.reject(error);
  }
);

/**
 * 发起get请求
 * @param {*} url
 * @param {*} params
 * @returns
 */
export const get = (url, params) => instance.get(url, {params})
 // console.log('params',params)


/**
 * 发起post请求
 * @param {*} url
 * @param {*} data
 * @returns
 */
export const post = (url, data) => instance.post(url, data);

export const del = (url) => instance.delete(url);
//自写
export const put=(url,data) => instance.put(url, data);

