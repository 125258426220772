
//默认url

export const serverUrl = 'https://back.kao-de-hao.cn';//h5地址
//export const serverUrl = 'http://192.168.2.105:9131';//后端地址

//export const serverUrl = 'http://39.107.49.143:9131';//线上地址

/**
 * 判断是否登录
 * @returns
 */
export const isLogin = () => localStorage.getItem('kaodehao-token');

/**
 * 设置token
 * @param {*} token
 * @returns
 */
export const setToken = (token) => localStorage.setItem('kaodehao-token', token);
//设置userSiger
export const setUserSiger = (userSiger) => localStorage.setItem('kaodehao-userSiger',userSiger)

/**
 * 清除localStorage
 * @returns
 */
export const removeToken = () => localStorage.removeItem('kaodehao-token');

// 获取userId
export const getUserId=()=>{
let userInfo=  localStorage.getItem('kaodehao-user')
userInfo=JSON.parse(userInfo)
   let appUserId=userInfo.appUserId
   return appUserId
}





