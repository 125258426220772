<template>
<div id="app">
    <TabBar />
    <FloatButton />
    <router-view/>
  </div>
</template>
<script>
import TabBar  from '@/components/TabBar';
import FloatButton from '@/components/floatButton'
export default {
  components:{
    TabBar,FloatButton
  },
  mounted(){
    document.addEventListener('plusready', function() {
            var webview = plus.webview.currentWebview();
            plus.key.addEventListener('backbutton', function() {
                webview.canBack(function(e) {
                    if(e.canBack) {
                        webview.back();
                    } else {
                        //webview.close(); //hide,quit
                        //plus.runtime.quit();
                        //首页返回键处理
                        //处理逻辑：1秒内，连续两次按返回键，则退出应用；
                        var first = null;
                        plus.key.addEventListener('backbutton', function() {
                            //首次按键，提示‘再按一次退出应用’
                            if (!first) {
                                first = new Date().getTime();
                                Toast({message:'再按一次退出应用'});
                                setTimeout(function() {
                                    first = null;
                                }, 1000);
                            } else {
                                if (new Date().getTime() - first < 1500) {
                                    plus.runtime.quit();
                                }
                            }
                        }, false);
                    }
                })
            });
        });

  }
};

</script>

<style lang="scss">
*{margin: 0;padding: 0;}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}


</style>
