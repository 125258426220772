import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '@/layout'
import { isLogin } from '@/utils/tools'
import { Dialog } from 'vant';
import {currentVersion,getVersionAPI} from '@/version'

Vue.use(VueRouter)

const routes = [
  //app 公用模块
  {
    path: '/',
    name: 'Home',
    component: () => import('@/views/home/index')
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/login/index')
  },
  {
    path: '/reg',
    name: 'Reg',
    component: () => import('@/views/reg/index')
  },
  {
    path: '/user',
    name: 'User',
    component: () => import('@/views/user/index')
  }, {
    path: '/user/editPassword',//修改密码
    name: 'EditPassword',
    component: () => import('@/views/user/editPassword')
  }, {
    path: '/user/editUserInfo',//修改用户信息
    name: 'EditUserInfo',
    component: () => import('@/views/user/editUserInfo')
  },
  {
    path: '/cart',
    name: 'Cart',
    component: () => import('@/views/cart/index')
  },
  {
    path: '/order',//订单列表
    name: 'Order',
    component: () => import('@/views/order/index')
  },
  {
    path: '/address',//地址列表
    name: 'Address',
    component: () => import('@/views/address/addressList'),
  },
  {
    path: '/address/edit',//修改地址
    name: 'AddressEdit',
    component: () => import('@/views/address/addressEdit')
  },
  {
    path: '/payConfirm',//支付页
    name: 'PayConfirm',
    component: () => import('@/views/pay/payConfirm')
  },
  {
    path:'/paySuccess',//支付成功页
    name:'PaySuccess',
    component:() => import('@/views/pay/paySuccess')
  },
  {
    path:'/user/myFileList',
    name:'MyFileList',
    component:() => import('@/views/user/myFileList')
  },




  // 书店模块
  {
    path: '/mall',//书店商品列表
    name: 'Mall',
    component: () => import('@/views/bookstore/mall/index')
  },
  {
    path: '/buyBookForm',//帮我买书页
    name: 'BuyBookForm',
    component: () => import('@/views/bookstore/buyBookForm')
  },
  {
    path: '/detail',//公用商品详情
    name: 'Detail',
    component: () => import('@/components/goodDetails')
  },


  // {
  //   path: '/list',//分类商品卡片列表
  //   name: 'List',
  //   component: () => import('@/views/bookstore/list/index')
  // },
  // 考得好模块
  {
    path: '/kaodehao',
    name: 'Kaodehao',
    component: () => import('@/views/kaodehao')
  },
  {
    path: '/oralArithmetic',//口算题卡
    name: 'oralArithmetic',
    component: Layout,
    redirect: '/oralArithmetic/list',//题目列表
    children:[
      {
        path:'list',
        name:'OralArithmeticList',
        component:()=>import('@/views/kaodehao/oral-arithmetic-card'),
        meta:{title:'口算题卡',icon:'dashboar'}
      },
      {
        path:'answerCard',//答题卡
        name:'AnswerCard',
        component:()=>import('@/views/kaodehao/oral-arithmetic-card/answer-card'),
      }
    ]
  },{//考得好知识点
    path:'/knowledgeBase',
    name:'gradeSubject',
    component: Layout,
    redirect: '/knowledgeBase/gradeSubject',//选择科目
    children:[{
      path:'gradeSubject',
      component:()=>import('@/views/kaodehao/knowledgeBase/grade-subject'),
      meta:{title:'选择科目'}
    },
    {
    path:'bookContent',//横屏知识点内容
    name: 'bookContent',
    component: ()=>import('@/views/kaodehao/knowledgeBase/bookContent'),
    meta:{title:'知识点'}

  },
  ]
    
  },
  //资源共享
  {
    path:'/kaodehao/resourceSharing',
    name:'resourceSharing',
    component:()=>import('@/views/kaodehao/resource-sharing')
  },
  //英语单词
  {
    path:'/kaodehao/EnglishWord',
    name:'EnglishWord',
    component:()=>import('@/views/kaodehao/EnglishWord')
  },
  {//单词轮播页面
    path:'/kaodehao/CarouselWord',
    name:'CarouselWord',
    component:()=>import('@/views/kaodehao/EnglishWord/CarouselWord')
  },
  // 跑腿模块
  {
    path:'/errand',//跑腿主页
    name:'errand',
    component:()=>import('@/views/errand')
  },
  {
    path:'/errand/sortDetails',//可接订单列表，跑腿订单池
    name:'sortDetails',
    component:()=>import('@/views/errand/SortDetails')
  },
  {
    path:'/errand/release',//发布跑腿订单
    name:'release',
    component:()=>import('@/views/errand/Release')
  },
  {
    path:'/errand/detail',//跑腿订单详情
    name:'detail',
    component:()=>import('@/views/errand/Detail')
  },
  {
    path:'/errand/order',//我的跑腿订单
    name:'order',
    component:()=>import('@/views/errand/Order')
  },
  {
    path:'/errand/errandReg',//注册跑腿员
    name:'errandReg',
    component:()=>import('@/views/errand/errandReg')
  },


  // 豫膳房
  {
    path: '/yushanfang',
    name: 'Yushanfang',
    component: () => import('@/views/yushanfang')
  },
  //邻来临往
  {
    path: '/linlailinwang',
    name: 'Linlailinwang',
    component: () => import('@/views/linlailinwang')
  },
  //黄页模块
  {
    path: '/yellowPages',
    name: 'YellowPages',
    component: () => import('@/views/yellowPages')
  },
  {//黄页发布
    path:"/yellowPages/yellowForm",
    name:"yellowForm",
    component: () => import('@/views/yellowPages/yellowForm')
  },
  {//黄页订单
    path:"/yellowPages/myYellowPagesOrder",
    name:"myYellowPagesOrder",
    component: () => import('@/views/yellowPages/myYellowPagesOrder')
  },
  {//黄页购买记录
    path:"/yellowPages/purchaseRecord",
    name:"purchaseRecord",
    component: () => import('@/views/yellowPages/purchaseRecord')
  },
  {//黄页商品详情
    path:"/yellowPages/yellowDetails",
    name:"yellowDetails",
    component: () => import('@/views/yellowPages/components/yellowDetails')
  },


  //聊天模块
  {
    path: '/chatroom/messageSendBox',
    name: 'MessageSendBox',
    component: () => import('@/views/chatroom/messageSendBox')
  },
  {
    path: '/chatroom/conversationList',
    name: 'ConversationList',
    component: () => import('@/views/chatroom/conversationList')
  },
  {
    path: '/chatroom/addConversation',
    name: 'AddConversation',
    component: () => import('@/views/chatroom/addConversation')
  },
  {
    path:'/chatroom/myHongbao',
    name:'MyHongbao',
    component: () => import('@/views/chatroom/myHongbao')
  },
  //广告商品模块
  {
    path:'/myAdvertisingOrder',
    name:'myAdvertisingOrder',
    component:()=>import('@/views/advertising/myAdvertisingOrder')
  },
  {
    path: '/myAdvertising',
    name: 'myAdvertising',
     component: () => import( '@/views/advertising/myAdvertising')
  },
  {
    path: '/myAdvertisingGoods',
    name: 'myAdvertisingGoods',
     component: () => import( '@/views/advertising/myAdvertisingGoods')
  },
  {
    path: '/advertisingForm',
    name: 'advertisingForm',
     component: () => import( '@/views/advertising/advertisingForm')
  },
  {//广告商品购买记录
    path:"/myAdvertisingPurchaseRecord",
    name:"myPurchaseRecord",
    component: () => import('@/views/advertising/purchaseRecord')
  },
  {
    path:'/advertisingDetails',
    name: 'advertisingDetails',
    component: ()=> import('@/views/advertising/advertisingDetails.vue')
  },

  //活动模块
  {//预售团购
    path:"/activity/presell",
    name:"presell",
    component: () => import('@/views/activity/presell')
  },
  {//预售团购
    path:"/activity/presellDetail",
    name:"presellDetaIl",
     component: () => import('@/views/activity/presell/presellDetaIl.vue')
  },//presellDetaIl



  // 测试页面
  {
    path: '/chatGPT',
    // name: 'Show',
    component: () => import('@/components/map')
  }

]



const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {

  // 验证token,只有存在token的时候，才能跳转到内容页

  // let token = isLogin()

  // if (token || to.path === "/login" || to.path === "/reg") {

  next();

  // } else {

  //   next("/login");

  // }

});

export default router
