import { post, get } from '@/utils/request';


//群发消息 http://192.168.0.111:9131/system/appUser/api/sendGroupMessage
export const  massTextAPI = (data) => post('/system/appUser/api/sendGroupMessage',data);

//获取小区学校用户列表http://192.168.0.111:9131/system/appUser/api/userInfo
export const  getUserListAPI = (data) => post('/system/appUser/api/userInfo',data);

//发起客服请求  http://192.168.0.105:9131/system/admin/api/allotService
export const getUserServiceAPI= (data) => post('/system/admin/api/allotService',data);

//发红包接口  http://192.168.2.105:9131/system/appRedPacket/api/sendRedPacket
export const sendRedPacketAPI= (data) => post('/system/appRedPacket/api/sendRedPacket',data);

//查看红包记录 http://192.168.2.105:9131/system/appRedPacket/api/selectByCondition
export const getHongbaoHistory= (data) => post('/system/appRedPacket/api/selectByCondition',data);