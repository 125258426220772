import { post, get } from '@/utils/request';

//获取栏目信息http://192.168.0.107:9131/study/appColumn/api/selectByPid

export const getColumnAPI = (number) => get(`/study/appColumn/api/selectByAppColumnId?appColumnId=${number}`);

//获取知识点 http://192.168.0.107:9131/study/appKnowledges/api/selectAllByColumnId

export const getKnowledgeAPI = (number) => get(`/study/appKnowledges/api/selectAllByColumnId?appColumnId=${number}`);

//查询科目版本及上下册 http://192.168.0.107:9131/study/appColumn/api/selectSubjectAndReact
export const getBookCoverAPI = (number) => get(`/study/appColumn/api/selectSubjectAndReact?appColumnId=${number}`)

//获取书籍目录 http://192.168.0.107:9131/study/appColumn/api/selectByPid
export const getCatalogueAPI=(number) => get(`/study/appColumn/api/selectByPid?appColumnId=${number}`)