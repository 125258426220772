<template>
  <!-- 图书目录 -->
  <div class="layout-menu" :style="{ width: width, height: height }">
    <div
      class="menu-item"
      v-for="(item, index) in list"
      :key="index"
      @click="toggleSubMenu(index, item)"
    >
      <div class="menu-item-title">
        {{ item.name ||'暂无内容' }}
      </div>

      <!-- 子菜单 -->
      <!-- <div class="sub-menu" v-show="item.open">
        <div class="sub-menu-item" v-for="(subItem, subIndex) in item.subItems" :key="subIndex">
         {{ subItem.name }}
          
        </div>
      </div> -->
    </div>

    <div class="menu-item" @click="toBackParent" v-if="list.length == 0">
      <div class="menu-item-title" style="color: #409eff">
        加载中<i class="el-icon-loading"></i>
      </div>
    </div>
    <div class="menu-item" @click="toBackParent">
      <div class="menu-item-title" style="color: #409eff">返回上一级</div>
    </div>
  </div>
</template>

<script>
import { getKnowledgeAPI, getColumnAPI } from "@/api/kaodehao/knowledges";
import { Toast } from 'vant';

export default {
  
  async mounted() {

    console.log("书目id", this.appColumnId);
    this.list=[]
    const res = await getColumnAPI(this.appColumnId);
    console.log("目录", res);
    this.list = res.data;
    
    localStorage.setItem('bookList',JSON.stringify(res.data));
  },
  watch:{
    list(oldValue,newValue){
      if(this.list==[]){
        setTimeout(()=>{
          Toast.fail('响应超时');
          this.$store.commit("SET_HTML", "");
        this.$store.commit("SET_RIGHTSIDE", '');
        this.$router.push("/knowledgeBase/gradeSubject");
        },6000)
      }
      
    } 
  },
  props: {
    // items: {
    //   type: Array,
    //   required: true,
    // },
    width: {
      type: String,
      default: "25vmin",
    },
    height: {
      type: String,
      default: "56vmin",
    },
    progressBarHeight: {
      type: String,
      default: "8px",
    },
    appColumnId: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      continuous:false,
      list: [],
      showProgressBar: false,
      pid: "",
     // type:'column'//栏目菜单还是知识点菜单
    };
  },
  methods: { //点击菜单事件
    async toggleSubMenu(index, item) {
     
      this.pid = this.list[0].pid;
      console.log("当前pid", this.pid);
      //如果有栏目信息，展示
      console.log("index", this.list[index]);
      if (this.list[index].content) {
        //如果有栏目信息html，展示
        this.$store.commit("SET_HTML", this.list[index].content);
        this.$store.commit("SET_RIGHTSIDE", this.list[index].rightSide);
      }
      this.list=[]
      const res = await getColumnAPI(item.appColumnId);
      if (res.data.length > 0) {
        this.list = res.data;
      //  this.type = res.type
      } else {
        this.$store.commit("SET_HTML", `<h1>暂无数据</h1>`);
        this.$store.commit("SET_RIGHTSIDE", '');
      }

      console.log("list1", this.list);

      if (this.list[0].level == 7) {
        //最后一级目录
        this.pid = this.list[0].pid || null;
       
        this.toggleSubMenu =this.toKnowledgesMenu
        //换成查看知识点的方法
      }
    },

//点击知识点菜单事件
    async toKnowledgesMenu(index, item){ 
     // this.type='knowledges'
          // this.pid=this.list[index].appColumnId;
          if(this.pid==this.appColumnId){this.toggleSubMenu =this.copy}//如果是在根目录，初始化点击菜单
            console.log('this.list[index].appColumnId',this.list[index].appColumnId)
          
          if (this.list[0].appKnowledgesId || null) {
            // 有知识点id,点击切换知识点在详情页展示
            if (item.content) {//SET_RIGHTSIDE
              this.$store.commit("SET_HTML", item.content);
              this.$store.commit("SET_RIGHTSIDE", item.rightSide);
            } else {
              this.$store.commit("SET_HTML", `<h1>暂无数据</h1>`);
              this.$store.commit("SET_RIGHTSIDE", '');
            }
          } else {
            this.list=[]
            // 如果没有知识点id 发送请求获取知识点列表
            const res = await getKnowledgeAPI(item.appColumnId);
            if (res.data.length > 0) {
              this.list = res.data;
            //  this.type=res.type
            } else {
              this.$store.commit("SET_HTML", `<h1>暂无数据</h1>`);
              this.$store.commit("SET_RIGHTSIDE", '');
              return;
            }
            console.log("知识点", this.list);
            this.list.forEach((e) => {
              e.name = e.title;
            });
            //    this.$store.commit('SET_HTML',this.list[0].content)
          }
        },


    async toBackParent() {
      //连续点击两次返回选择科目页
      if(this.continuous){
        this.$store.commit("SET_HTML", "");
        this.$store.commit("SET_RIGHTSIDE", '');
        this.$router.push("/knowledgeBase/gradeSubject");
        return
      } 
      this.continuous=true
      let timer=setTimeout(()=>{
        this.continuous=false
      },500)
      

//如果有level，初始化点击菜单方法
if(this.list[0].level){
  console.log('换成栏目查找方法')
   this.toggleSubMenu = this.copy; 
}
// if(this.type=='column'){
//   console.log('换成栏目查找方法')
//     this.toggleSubMenu = this.copy; 
// }
     


      console.log("pid", this.pid);
      if (!this.pid ||  this.list[0].pid == this.appColumnId) {
        this.toggleSubMenu = this.copy; 
        console.log("最上一级");
        this.$store.commit("SET_HTML", "");
        this.$store.commit("SET_RIGHTSIDE", '');
        this.$router.push("/knowledgeBase/gradeSubject");
        return;
      }
      // this.pid=this.list[0].pid
      // console.log('返回中pid', this.pid);
      this.list=[]
      const res = await getColumnAPI(this.pid);
      this.list = res.data;
      this.pid = this.appColumnId;
      //  console.log("返回后pid", this.pid)
      // this.pid = this.appColumnId;
    },

    async copy(index, item) {
      console.log('初始化事件')
      this.pid = this.list[0].pid;
      console.log("当前pid", this.pid);
      //如果有栏目信息，展示
      console.log("index", this.list[index]);
      if (this.list[index].content) {
        //如果有栏目信息html，展示
        this.$store.commit("SET_HTML", this.list[index].content);
        this.$store.commit("SET_RIGHTSIDE", this.list[index].rightSide);
      }
      this.list=[]
      const res = await getColumnAPI(item.appColumnId);
      if (res.data.length > 0) {
        this.list = res.data;
       // this.type=res.type
      } else {
        this.$store.commit("SET_HTML", `<h1>暂无数据</h1>`);
        this.$store.commit("SET_RIGHTSIDE", '');
        
      }

      console.log("list1", this.list);

      if (this.list[0].level == 7) {
        //最后一级目录
        this.pid = this.list[0].pid || null;
        this.toggleSubMenu =this.toKnowledgesMenu
        //换成查看知识点的方法
      }
    },
 
  },
};
</script>

<style lang="scss" scoped>
.layout-menu {
  overflow-y: auto;
  background-color: #313131;
  // color:#909399
  color: white;

.menu-item-title {
  text-align: start;
  padding-left: 8px;
  padding-bottom: 2px;
  padding-top: 2px;
  margin: 4px;
  box-sizing: border-box;
  border: 1px solid #909399;
  background-color: #3d3d3d;
  border-radius: 5%;
  font-size: 13px;
  line-height: 16px;
}
.menu-item {
  //  background-color: #313131;
  cursor: pointer;

  /* padding: 2px; */
  /* border: 1px solid #ccc; */
}
.sub-menu {
  /* margin-left: 10px; */
  padding: 2px;
  /* border-left: 1px solid #ccc; */
}
.sub-menu-item {
  font-size: 12px;
  text-align: start;
  padding-left: 16px;
}
.sub-menu-item-text {
  //
}
}
</style>


