<template>
  <div class="layout"  id="gyroContain">
      <!-- <sideBar />
      <tabBar /> -->
      <el-container>
        <!-- 上方导航栏 -->
<el-header>
  <div class="layout-icon" @click="$router.push('/')">
    <van-icon name="wap-home-o" />
  </div>
  

</el-header>
<el-container>
  <!-- 最左侧导航栏 -->
  <el-aside width="64px">
      <el-menu
      router
      background-color="#343a4a"
    default-active="1"
    class="el-menu-vertical-demo"
    :collapse="true"
   >
   <el-menu-item index="/mall">
    <van-icon name="shop-o" />
      <span slot="title">书店</span>
    </el-menu-item>
    <el-menu-item index="/kaodehao">
      <van-icon name="medal-o" />
      <span slot="title">考的好</span>
    </el-menu-item>
    <el-menu-item index="/cart">
      <van-icon name="shopping-cart-o" />
      <span slot="title">购物车</span>
    </el-menu-item>
    <el-menu-item index="/user">
      <van-icon name="user-o" />
      <span slot="title">我的</span>
    </el-menu-item>
  </el-menu>
  </el-aside>
  <el-aside v-if="isShowTextbook"  width="25vmin">
    <!-- 教科书封面 -->
      <div class="imgBox">
      <img :src="headImage" alt="封面">
  </div>
  <!-- 教科书目录 -->
  <textbookDirectory :items="items" :appColumnId="appColumnId" />
  </el-aside>
  <!-- 主体页面 -->
  <el-main>
    <div style="position: relative;width: 100%;height: 100%;" >
       <router-view :key="key"></router-view>
    </div>
  </el-main>
</el-container>
</el-container>
  </div>
</template>

<script>
import textbookDirectory from '@/layout/components/textbookDirectory.vue'
// import sideBar from '@/layout/sideBar.vue'
// import tabBar from '@/layout/tabBar.vue'


export default {
  name: 'layout',
  components: {
     textbookDirectory
  },
  data(){
    return {
      headImage:''
    }
  },
  computed: {
  key() {
    return this.$route.path
  }
},
created(){
  //  if(this.$route.query.appColumnId){
 
  //    this.appColumnId=this.$route.query.appColumnId
  //  }
  
},
watch:{
  $route(route) {
   
    if(this.$route.query.appColumnId){
 
 this.appColumnId=this.$route.query.appColumnId
}
    if (route.path.includes('bookContent')) {
      this.isShowTextbook=true
      this.headImage=this.$route.query.headImage.length?'https://www.kao-de-hao.cn'+this.$route.query.headImage:'images/defaultCover'
    }else{
      this.isShowTextbook=false
    }
   
  },

},
  data() {
  return {
    appColumnId:'',//栏目id
    isShowTextbook:false,//是否显示左侧教科书栏
    // 教材目录
    items: [
      {
        title: "菜单1",
        open: false,
        subItems: ["子菜单1", "子菜单2", "子菜单3", "子菜单4", "子菜单5", "子菜单6", "子菜单7", "子菜单8", "子菜单9"],
      },
      {
        title: "菜单2",
        open: false,
        subItems: ["子菜单1", "子菜单2", "子菜单3", "子菜单4", "子菜单5", "子菜单6", "子菜单7", "子菜单8", "子菜单9"],
      },
      {
        title: "菜单3",
        open: false,
        subItems: ["子菜单1", "子菜单2", "子菜单3", "子菜单4", "子菜单5", "子菜单6", "子菜单7", "子菜单8", "子菜单9"],
      },
      {
        title: "菜单4",
        open: false,
        subItems: ["子菜单1", "子菜单2", "子菜单3", "子菜单4", "子菜单5", "子菜单6", "子菜单7", "子菜单8", "子菜单9"],
      },
      {
        title: "菜单5",
        open: false,
        subItems: ["子菜单1", "子菜单2", "子菜单3", "子菜单4", "子菜单5", "子菜单6", "子菜单7", "子菜单8", "子菜单9"],
      },
      {
        title: "菜单6",
        open: false,
        subItems: ["子菜单1", "子菜单2", "子菜单3", "子菜单4", "子菜单5", "子菜单6", "子菜单7", "子菜单8", "子菜单9"],
      },
      {
        title: "菜单7",
        open: false,
        subItems: ["子菜单1", "子菜单2", "子菜单3", "子菜单4", "子菜单5", "子菜单6", "子菜单7", "子菜单8", "子菜单9"],
      },
      {
        title: "菜单8",
        open: false,
        subItems: ["子菜单1", "子菜单2", "子菜单3", "子菜单4", "子菜单5", "子菜单6", "子菜单7", "子菜单8", "子菜单9"],
      },
      {
        title: "菜单9",
        open: false,
        subItems: ["子菜单1", "子菜单2", "子菜单3", "子菜单4", "子菜单5", "子菜单6", "子菜单7", "子菜单8", "子菜单9"],
      },
    ],
  };
},
  methods: {
      //打开关闭子菜单
    handleOpen(key, keyPath) {
      console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath);
    }
  }
}
</script>
<style lang="scss" scoped>
//强制横屏
@media screen and (orientation: portrait) {
  html{
      width : 100vmin;
      height : 100vmax;
  }
  body{
          width : 100vmin;
          height : 100vmax;
  }
  #gyroContain{
      width : 100vmax;
      height : 100vmin;
      transform-origin: top left;
      transform: rotate(90deg) translate(0,-100vmin);
  }
}
@media screen and (orientation: landscape) {
  html{
      width : 100vmax;
      height : 100vmin;
  }
  body{
      width : 100vmax;
      height : 100vmin;
  }
  #gyroContain{
      width : 100vmax;
      height : 100vmin;
  }
}
.layout-icon{
  height: 100%;
  padding-top: 2px;
}
.layout{
  width: 100vmax;
  height: 100vmin;
  overflow: hidden;
}
.el-header{
  background-color: rgb(52,58,74);
  color:#FFF ;
  text-align: center;
  line-height: 12vmin;
  height: 10vmin !important;
  font-size: 14px;
  text-align: start;
  i{
       font-size: 24px;
  }
}
.el-aside {
  background-color: rgb(52,58,74);
  color:#D3DCE6 ;
  text-align: center;
  height: 90vmin;
  
}
.imgBox{
  width: 24vmin;
  height: 32vmin;
  overflow: hidden;
  padding: -3px;
  margin: 0.5vmin;
  img{
      width: 100%;
      height: 100%;
  }
}
.el-main {
  background-color:#E9EEF3 ;
  color: #606266;
  text-align: center;
  height: 90vmin;
  padding: 20px;
}
.el-submenu ,.el-submenu__title{
  height: 12vmin;
  line-height: 12vmin;
  font-size:12px;
}
.el-menu-item{
  height: 10vmin;
  line-height: 10vmin;
  font-size:12px;
}
.el-menu{
  border: none;
}
.van-icon{
  font-size: 24px;
  color:#FFFFFF
}
</style>
