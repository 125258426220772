export function htmlFilter(html) {
    if(!html) {
        return html;
    }
    //过滤图片地址，因为图片域名换了
    const pattern = /<img.*?src="(.*?)".*?>/g;
    const imgTags = html.match(pattern);
    if (imgTags) {
  
    for (let i = 0; i < imgTags.length; i++) {
      const tag = imgTags[i];
      const src = tag.match(/src="(.*?)"/)[1];
  
      if (!src.startsWith('http')) {
        const newSrc = `https://www.kao-de-hao.cn${src}`;
        html = html.replace(src, newSrc);
      } else {
        const newSrc = src.replace(src.split('/uploads')[0], 'https://www.kao-de-hao.cn');
        html = html.replace(src, newSrc);
      }
    }
    }
    return html;
  }