<template>
  <div class="float-button">
    <div class="button-group" v-if="isShow">
      <div class="button-container">
        <van-button type="info" round @click="customerService">
          <div style="display: flex; font-size: 12px">
            <span>官方</span>
            <span>客服</span>
          </div>
        </van-button>
        <van-button type="primary" round @click="showShare = true">
          <div style="display: flex; font-size: 12px">
            <span>分享</span>
            <span>有礼</span>
          </div>
        </van-button>
      </div>
    </div>
    <!-- 分享框 -->
    <van-share-sheet
      v-model="showShare"
      title="立即分享给好友"
      :options="options"
    />
  </div>
</template>
<script>
import { Dialog } from "vant";
import { getUserServiceAPI } from "@/api/chatroom/index";

export default {
  name: "FloatButton",
  data() {
    return {
      isShow: true, //显示按钮
      showShare: false, //显示分享页
      options: [
        [
          { name: "微信", icon: "wechat" },
          { name: "朋友圈", icon: "wechat-moments" },
          { name: "微博", icon: "weibo" },
          { name: "QQ", icon: "qq" },
        ],
        [
          { name: "复制链接", icon: "link" },
          { name: "分享海报", icon: "poster" },
          { name: "二维码", icon: "qrcode" },
          { name: "小程序码", icon: "weapp-qrcode" },
        ],
      ],
    };
  },
  watch: {
    $route: "getPath",
  },
  methods: {
    async customerService() {
      const res = await getUserServiceAPI();
      console.log("data", res.data);
      if (res.dada == null || res.dada.length == 0) {
        Dialog.alert({
          title: "您的客服不在线",
          message: "请换个时间联系吧",
        }).then(() => {
          // on close
        });
      }else{
        this.$router.push({path:"/chatroom/messageSendBox",query:{userID:res.data[0]}})
      }
    },
    getPath() {
      if (
        this.$route.path == "/chatroom/messageSendBox" ||
        this.$route.path == "/kaodehao/EnglishWord" ||
        this.$route.path.includes("oralArithmetic") ||
        this.$route.path.includes("knowledgeBase")
      ) {
        this.isShow = false;
      } else {
        this.isShow = true;
      }
    },
  },
};
</script>
<style scoped lang="scss">
.float-button {
  .van-button {
    width: 40px;
    height: 40px;
    flex-direction: row;
    margin: 4px;
  }
  .button-group {
    z-index: 1000;
    position: fixed;
    right: 0;
    bottom: 60px;
    display: flex;
    justify-content: flex-end;
  }

  .button-container {
    display: flex;
    flex-direction: column;
  }
}
</style>