import { post, get } from './utils/request';
import { Dialog } from 'vant';
export const currentVersion = '1.0'
//获取最新版本
export const getVersionAPI = (data) => post('/system/appVersionUpdate/selectNew', data);

// function beforeClose(action, done) {
//     window.open('http://39.107.49.143:9131/system/appDownload/download?fileFullName=kaodehao.apk', '_blank')
//   }




getVersionAPI().then(res => {

    if (res && res.appNum != currentVersion) {
        console.log('版本不对')
        Dialog.alert({
            beforeClose:(action, done)=>{
             window.open('http://39.107.49.143:9131/system/appDownload/download?fileFullName=kaodehao.apk', '_blank')},
            title: '您的版本已过期',
            message: '请下载最新版本',
        }).then(() => {
           
        });
    }
})