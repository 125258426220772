<template>
  <div>
    <van-tabbar v-model="active" v-if="tabbarIsShow">
      <van-tabbar-item
        :icon="item.icon"
        :to="item.path"
        v-for="item in tabbarList"
        :key="item.path"
        >{{ item.text }}</van-tabbar-item
      >
    </van-tabbar>
  </div>
</template>
<script>
export default {
  data() {
    return {
      tabbarList: [
        { id: 1, icon: "home-o", text: "首页", path: "/" },
        { id: 2, icon: "shop", text: "书店", path: "/mall" },
        { id: 3, icon: "medal-o", text: "考得好", path: "/kaodehao" },
        { id: 4, icon: "shopping-cart", text: "购物车", path: "/cart" },
        { id: 5, icon: "user-o", text: "我的", path: "/user" },
      ], //初始导航栏
      active: 0,
      tabbarIsShow: true,
    };
  },
  //监听路由变化
  watch: {
    $route: "getPath",
  },

  methods: {
    findIdByPath(arr, path) {
      // 遍历数组，查找符合条件的子对象
      for (let i = 0; i < arr.length; i++) {
        // 判断当前子对象的path值是否等于输入的path值
        if (arr[i].path === path) {
          // 如果相等，返回该子对象的id值
          return i;
        }
      }
      // 如果没有找到，返回0
      return 6;
    },
    getPath() {
      this.active = this.findIdByPath(this.tabbarList, this.$route.path);
      console.log(this.$route.path);
      console.log(this.$route.path.includes("chatroom"));
      //登录注册页面隐藏tabbar
      if (
        ["/login","/cart","/detail","/reg","/activity/presellDetaIl"].includes(this.$route.path) ||
        this.$route.path.includes("oralArithmetic") ||
        this.$route.path.includes("yellowPages") ||
        this.$route.path.includes("knowledgeBase") ||
        this.$route.path.includes("EnglishWord") ||
        this.$route.path.includes("dvertising")||
        this.$route.path.includes("chatroom")
      ) {
        this.tabbarIsShow = false;
       
      } else {
        this.tabbarIsShow = true;
        
      }
    },
  },
};
</script>
<style scoped>
.van-tabbar {
  border-top: 1px solid #ccc;
}
</style>
